import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Autocert exists to make it easy to use mutual TLS to improve security within a cluster and to secure communication into, out of, and between Kubernetes clusters.
To get a certificate you simply need to telll autocert your workload's name, using the `}<inlineCode parentName="p">{`autocert.step.sm/name`}</inlineCode>{`pod annotation.
Autocert will issue a cert to the pod, make it available in `}<inlineCode parentName="p">{`var/run/autocert.step.sm`}</inlineCode>{`, and keep it renewed.
It requires a certificate authority to issue certificates.
This guide shows how to configure autocert to use Certificate Manager as the upstream CA.`}</p>
    <h2>{`Before you begin`}</h2>
    <p>{`You will need:`}</p>
    <ul>
      <li parentName="ul">{`An account on the Smallstep platform. Need one? `}<a parentName="li" {...{
          "href": "https://smallstep.com/signup?product=cm"
        }}>{`Register here`}</a></li>
      <li parentName="ul">{`An Authority in Certificate Manager that will act as your upstream CA`}</li>
    </ul>
    <h2>{`Bootstrap with your CA`}</h2>
    <p>{`Bootstrapping with your Authority configures your workstation to trust the authority's root certificate.`}</p>
    <p>{`Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca bootstrap --ca-url [your CA URL] \\
    --fingerprint [your CA fingerprint] \\
    --install
`}</code></pre>
    <h2>{`Add a provisioner`}</h2>
    <p>{`Autocert requires a JWK provisioner (it's the default type).
Let's call it `}<inlineCode parentName="p">{`autocert`}</inlineCode>{`. Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca provisioner add autocert --create
`}</code></pre>
    <p>{`You’ll be asked to provide a password to encrypt the provisioner private key.`}</p>
    <h2>{`Create ConfigMaps and a Secret for Autocert`}</h2>
    <p>{`In Kubernetes, create a namespace for autocert:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`kubectl create ns step
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`namespace/step created
`}</code></pre>
    <p>{`Use the same password you entered when creating the provisioner to create the Secret.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`kubectl -n step create secret generic autocert-password --from-file=password=autocert-password.txt
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`secret/autocert-password created
`}</code></pre>
    <p>{`Now create a `}<inlineCode parentName="p">{`ConfigMap`}</inlineCode>{` that includes your (bootstrapped) config dir:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`kubectl -n step create configmap config --from-file $(step path)/config\`
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`configmap/config created
`}</code></pre>
    <p>{`We'll do the same thing for the `}<inlineCode parentName="p">{`certs`}</inlineCode>{` dir, which contains our CA's root certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`kubectl -n step create configmap certs --from-file $(step path)/certs
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`configmap/certs created
`}</code></pre>
    <h2>{`Deploy Autocert`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Download the yaml config:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`curl -O https://raw.githubusercontent.com/smallstep/autocert/master/install/02-autocert.yaml
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Edit the `}<inlineCode parentName="p">{`caUrl`}</inlineCode>{` in the `}<inlineCode parentName="p">{`autocert-config`}</inlineCode>{` ConfigMap in the `}<inlineCode parentName="p">{`02-autocert.yaml`}</inlineCode>{` file you just downloaded. Change it from `}<inlineCode parentName="p">{`https://ca.step.svc.cluster.local`}</inlineCode>{` to your Certificate Manager authority URL, e.g. `}<inlineCode parentName="p">{`https://autocert.areed.ca.smallstep.com`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`kubectl apply -f https://raw.githubusercontent.com/smallstep/autocert/master/install/03-rbac.yaml
`}</code></pre>
        <p parentName="li">{`Output:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`clusterrole.rbac.authorization.k8s.io/autocert-controller created
clusterrolebinding.rbac.authorization.k8s.io/autocert-controller created
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Now let's deploy autocert. Run:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`kubectl apply -f 02-autocert.yaml
`}</code></pre>
        <p parentName="li">{`Output:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`service/autocert created
configmap/autocert-config created
deployment.apps/autocert created
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`And, let's deploy the admission webhook:`}</p>
        <p parentName="li">{`You can see in this block that we're including the root CA certificate (in base64) as part of the client configuration for autocert.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`cat <<EOF | kubectl apply -f -
apiVersion: admissionregistration.k8s.io/v1
kind: MutatingWebhookConfiguration
metadata:
  name: autocert-webhook-config
  labels: {app: autocert}
webhooks:
  - name: autocert.step.sm
    sideEffects: None
    admissionReviewVersions: ["v1beta1"]
    clientConfig:
      service:
        name: autocert
        namespace: step
        path: "/mutate"
      caBundle: $(cat $(step path)/certs/root_ca.crt | base64 | tr -d '\\n')
    rules:
      - operations: ["CREATE"]
        apiGroups: [""]
        apiVersions: ["v1"]
        resources: ["pods"]
    namespaceSelector:
      matchLabels:
        autocert.step.sm: enabled
EOF
`}</code></pre>
        <p parentName="li">{`output:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`mutatingwebhookconfiguration.admissionregistration.k8s.io/autocert-webhook-config created
`}</code></pre>
      </li>
    </ol>
    <p>{`Autocert is now added to the cluster and configured.
You can run this command to verify the autocert pods are marked Ready.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`kubectl -n step get deployment/autocert
`}</code></pre>
    <h2>{`Usage`}</h2>
    <p>{`Let’s create a test app that will use autocert.
It’s a “Hello World” web server that uses mutual TLS authentication.`}</p>
    <p>{`Since this deployment is in the default namespace, label it to tell autocert to issue and renew certificates for new pods with the `}<inlineCode parentName="p">{`autocert.step.sm/name`}</inlineCode>{` annotation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`kubectl label namespace default autocert.step.sm=enabled
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`namespace/default labeled
`}</code></pre>
    <p>{`To test things out, we'll create a deployment with the `}<inlineCode parentName="p">{`autocert.step.sm/name`}</inlineCode>{` pod annotation.
This example uses the name `}<inlineCode parentName="p">{`localhost`}</inlineCode>{`, since we will be testing from our workstation.`}</p>
    <pre><code parentName="pre" {...{}}>{`cat <<EOF | kubectl apply -f -
apiVersion: apps/v1
kind: Deployment
metadata:
  labels:
    app: hello-mtls
  name: hello-mtls
spec:
  selector:
    matchLabels:
      app: hello-mtls
  template:
    metadata:
      annotations:
        autocert.step.sm/name: localhost
      labels:
        app: hello-mtls
    spec:
      containers:
      - image: smallstep/hello-mtls-server-go:latest
        name: hello-mtls
EOF
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`deployment.apps/hello-mtls created
`}</code></pre>
    <p>{`For testing, forward `}<inlineCode parentName="p">{`localhost:8443`}</inlineCode>{` to port 443 on the pod.`}</p>
    <pre><code parentName="pre" {...{}}>{`kubectl port-forward deploy/hello-mtls 8443:443
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Forwarding from 127.0.0.1:8443 -> 443
Forwarding from [::1]:8443 -> 443
`}</code></pre>
    <p>{`Keep this running in the background during the next steps.`}</p>
    <p>{`Now issue a client certificate signed by your CA.
You’ll need this to authenticate to the “Hello mTLS” test server.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca certificate andrew@smallstep.com areed.crt areed.key
`}</code></pre>
    <p>{`Finally, you should be able to verify it's all working:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl --cacert $(step path)/certs/root_ca.crt \\
     --cert areed.crt --key areed.key \\
     https://localhost:8443
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Hello andrew@smallstep.com!
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      